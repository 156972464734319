import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Hero from "../components/Hero";

const Terms = ({ location, data }) => {
    const pagedata = data.pagedata.childMarkdownRemark.frontmatter
    const pagehtml = data.pagedata.childMarkdownRemark.html
  return (
    <>
      <Helmet defer={false} htmlAttributes={{lang: 'en',}}>
        <title>{pagedata.seotitle}</title>
        <meta name="description" content={pagedata.seometadesc} />
      </Helmet>
      <div id="top" className="invisible"></div>
      <Layout location={location}>
        <Hero location={location} />
        <div className="container mx-auto px-4 text-theme-primary-text">
            <h2 className=" font-sans-serif text-2xl py-4">Terms of Use</h2>
            <div className="cmscontent" dangerouslySetInnerHTML={{ __html: pagehtml}} />
        </div>
      </Layout>
    </>
  )
}

export default Terms

export const pageQuery = graphql`
  query {
    pagedata: file(relativePath: {eq: "page/terms-of-service.md"}) {
        childMarkdownRemark {
          html
          frontmatter {
            title
            seotitle
            seometadesc
          }
        }
      }
  }
`